import React from "react";

interface Props {
  children: JSX.Element[] | JSX.Element | string;
}

export const Body = (props: Props): JSX.Element => {
  return (
    <div className="mx-auto sm:px-5 sm:py-5 sm:container">{props.children}</div>
  );
};

export const BodyFluid = (props: Props): JSX.Element => {
  return <div className="mx-auto sm:px-5 sm:py-5">{props.children}</div>;
};
