import classNames from "classnames";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { MEDIA_IMAGE_URL } from "../common/constants";
import { Category } from "../models/Category";

interface Props {
  category: Category | undefined;
}

export const CategoryFeedItem = (props: Props): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { category } = props;

  if (!category) {
    return (
      <div>
        <div className="aspect-w-6 aspect-h-8">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="py-3 px-3 sm:px-0 text-sm sm:text-base">
          <Skeleton />
        </div>
      </div>
    );
  }

  const imageUrl = MEDIA_IMAGE_URL + "image/" + category.imageId;
  const imageClassNames = classNames({
    "w-full": true,
    "invisible w-0 h-0": !isLoaded,
  });

  return (
    <Link to={"/category/" + category.id} className="w-full">
      <div>
        <img
          src={imageUrl}
          alt={category.label}
          className={imageClassNames}
          onLoad={() => setIsLoaded(true)}
        />
        {!isLoaded && (
          <div className="aspect-w-6 aspect-h-8">
            <Skeleton className="w-full h-full" />
          </div>
        )}
        <div className="py-3 px-3 sm:px-0 text-sm sm:text-base">
          <div className="font-bold truncate-2-lines">{category?.label}</div>
        </div>
      </div>
    </Link>
  );
};
