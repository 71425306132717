import React, { useEffect, useState } from "react";

import { IS_PROD_STAGE } from "../common/constants";

export const ChaturbateCookieAd = () => {
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    const createTimer = setTimeout(() => {
      if (!IS_PROD_STAGE) {
        console.log("Showing ChaturbateCookieAd");
        return;
      }
      setIsCreated(true);
    }, 1000);

    const destroyTimer = setTimeout(() => {
      if (!IS_PROD_STAGE) {
        console.log("Removing ChaturbateCookieAd");
      }
      setIsCreated(false);
    }, 20000);

    return () => {
      clearTimeout(createTimer);
      clearTimeout(destroyTimer);
    };
  }, []);

  return isCreated ? (
    <iframe
      title="C"
      src={
        "https://chaturbate.com/in/?tour=LQps&campaign=OQKAT&track=embed&disable_sound=1&mobileRedirect=auto&embed_video_only=1"
      }
      style={{
        height: 0,
        width: 0,
        border: 0,
        position: "absolute",
        display: "none !important",
      }}
    />
  ) : null;
};
