import React from "react";
import { ClockIcon } from "@heroicons/react/solid";
import { QueryTime } from "../models/QueryTime";

interface Props {
  onChange: Function;
}

export const getLimitByTimeDate = (queryTime: QueryTime): Date | undefined => {
  if (queryTime === QueryTime.ALL_TIME) {
    return undefined;
  }
  if (queryTime === QueryTime.YEAR) {
    return new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);
  }
  if (queryTime === QueryTime.MONTH) {
    return new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
  }
  if (queryTime === QueryTime.WEEK) {
    return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  }
  if (queryTime === QueryTime.DAY) {
    return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  }
  return undefined;
};

export const LimitByTime = (props: Props): JSX.Element => {
  const stringToQueryTime = (option: string): QueryTime | undefined => {
    if (option === "All Time") {
      return QueryTime.ALL_TIME;
    }
    if (option === "This year") {
      return QueryTime.YEAR;
    }
    if (option === "This month") {
      return QueryTime.MONTH;
    }
    if (option === "This week") {
      return QueryTime.WEEK;
    }
    if (option === "This day") {
      return QueryTime.DAY;
    }
    return undefined;
  };

  return (
    <div className="flex items-center">
      <ClockIcon className="h-10 w-10 mr-2 md:mt-0.5 md:-mb-0.5" />
      <div className="w-20">TIME</div>
      <select
        className="form-select block w-full rounded-md text-red-700 bg-red-100 border-none"
        onChange={(event) =>
          props.onChange(stringToQueryTime(event.target.value))
        }
      >
        <option>All time</option>
        <option>This year</option>
        <option>This month</option>
        <option>This week</option>
        <option>This day</option>
      </select>
    </div>
  );
};
