import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  to: string;
  isMobile: boolean;
  isActive: boolean;
}

export const NavigationItem = (props: Props): JSX.Element => {
  const { label, to, isMobile, isActive } = props;

  const className = classNames("px-3 py-2 rounded-md text-sm font-medium", {
    "text-red-700 bg-red-100": isActive,
    "text-gray-500 hover:bg-red-100 hover:text-red-700": !isActive,
    block: isMobile,
  });

  return (
    <Link to={to} className={className}>
      {label}
    </Link>
  );
};
