import React from "react";
import { MEDIA_IMAGE_URL, MEDIA_VIDEO_URL } from "../common/constants";
import { Clip } from "../models/Clip";
import Plyr from "plyr-react";
import "../assets/plyr.css";

interface Props {
  clip: Clip | undefined;
}

export const VideoPlayer = React.memo<Props>((props) => {
  const { clip } = props;
  const videoUrl = MEDIA_VIDEO_URL + "video/" + clip?.videoId;
  const imageUrl = MEDIA_IMAGE_URL + "image/" + clip?.imageId;

  return (
    <Plyr
      source={{
        type: "video",
        title: clip?.label,
        poster: imageUrl,
        sources: [{ src: videoUrl, type: "video/mp4" }],
      }}
      options={{
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "pip",
          "fullscreen",
        ],
        settings: ["captions", "quality", "speed", "loop"],
        autoplay: true,
        loop: { active: true },
        ratio: "16:9",
      }}
    />
  );
});
