import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import usePageTracking from "./common/usePageTracking";
import ScrollToTop from "./components/ScrollToTop";
import { CategoriesRoute } from "./routes/CategoriesRoute";
import { CategoryRoute } from "./routes/CategoryRoute";
import { ClipRoute } from "./routes/ClipRoute";
import { HotRoute } from "./routes/HotRoute";
import { NewRoute } from "./routes/NewRoute";
import { NotFoundRoute } from "./routes/NotFoundRoute";
import { SearchRoute } from "./routes/SearchRoute";
import { StreamerRoute } from "./routes/StreamerRoute";
import { StreamersRoute } from "./routes/StreamersRoute";
import { TrendingRoute } from "./routes/TrendingRoute";
import { ChaturbateCookieAd } from "./components/ChaturbateCookieAd";

const AppSwitch = (): JSX.Element => {
  usePageTracking();

  return (
    <Switch>
      <Route exact path="/" component={HotRoute} />
      <Route exact path="/trending" component={TrendingRoute} />
      <Route exact path="/new" component={NewRoute} />
      <Route path="/clip/:id" component={ClipRoute} />
      <Route path="/post/:id" component={ClipRoute} />
      <Route path="/streamer/:id" component={StreamerRoute} />
      <Route path="/category/:id" component={CategoryRoute} />
      <Route path="/streamers" component={StreamersRoute} />
      <Route path="/categories" component={CategoriesRoute} />
      <Route path="/search" component={SearchRoute} />
      <Route component={NotFoundRoute} />
    </Switch>
  );
};

const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <ChaturbateCookieAd />
      <ScrollToTop />
      <AppSwitch />
    </BrowserRouter>
  );
};

export default App;
