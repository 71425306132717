import React from "react";
import { Category } from "../models/Category";
import { CategoryFeedItem } from "./CategoryFeedItem";

interface Props {
  categories: Category[];
}

export const CategoryFeed = (props: Props): JSX.Element => {
  const { categories } = props;
  const feedItems = categories.map((category) => (
    <CategoryFeedItem category={category} key={category.id} />
  ));
  const skeletonFeedItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => (
    <CategoryFeedItem category={undefined} key={id} />
  ));

  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-2 p-4 sm:p-0 sm:grid-cols-feedSmall sm:gap-x-4 sm:gap-y-2">
      {categories.length ? feedItems : skeletonFeedItems}
    </div>
  );
};
