import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { STAGE } from "./constants";
import { Stage } from "../models/Stage";

// From https://stackoverflow.com/a/63249329

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  // Init UA.
  useEffect(() => {
    if (STAGE === Stage.PROD) {
      ReactGA.initialize("UA-79272440-3");
    } else {
      ReactGA.initialize("UA-00000000-0", {
        debug: true,
        testMode: true,
      });
    }
    setInitialized(true);
  }, []);

  // Track UA.
  useEffect(() => {
    if (initialized) {
      if (STAGE === Stage.PROD) {
        ReactGA.pageview(location.pathname + location.search);
      } else {
        console.log("UA Pageview: " + location.pathname + location.search);
      }
    }
  }, [initialized, location]);

  // Track GA4.
  useEffect(() => {
    if (window.gtag) {
      if (STAGE === Stage.PROD) {
        window.gtag("send", "page_view", {
          page_location: location.href,
          page_path: location.pathname + location.search,
        });
      } else {
        console.log("GA4 Pageview: " + location.pathname + location.search);
      }
    }
  }, [location]);
};

export default usePageTracking;
