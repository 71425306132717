import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogoWithText } from "./Logo";
import { NavigationItem } from "./NavigationItem";

interface Props {
  page?: string;
}

export const Navigation = (props: Props): JSX.Element => {
  const { page } = props;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuClosedClassNames = classNames("h-6 w-6", {
    block: isMobileMenuOpen,
    hidden: !isMobileMenuOpen,
  });
  const mobileMenuOpenClassNames = classNames("h-6 w-6", {
    block: !isMobileMenuOpen,
    hidden: isMobileMenuOpen,
  });
  const mobileItemClassNames = classNames("md:hidden", {
    hidden: !isMobileMenuOpen,
  });
  const onClickMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="mx-auto px-4 sm:px-5">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <LogoWithText />
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavigationItem
                  label="HOT"
                  to="/"
                  isMobile={false}
                  isActive={page === "hot"}
                />
                <NavigationItem
                  label="TRENDING"
                  to="/trending"
                  isMobile={false}
                  isActive={page === "trending"}
                />
                <NavigationItem
                  label="NEW"
                  to="/new"
                  isMobile={false}
                  isActive={page === "new"}
                />
                <NavigationItem
                  label="STREAMERS"
                  to="/streamers"
                  isMobile={false}
                  isActive={page === "streamers"}
                />
                <NavigationItem
                  label="CATEGORIES"
                  to="/categories"
                  isMobile={false}
                  isActive={page === "categories"}
                />
                <NavigationItem
                  label="SEARCH"
                  to="/search"
                  isMobile={false}
                  isActive={page === "search"}
                />
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-red-700 hover:text-red-500 focus:outline-none"
              onClick={onClickMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={mobileMenuOpenClassNames}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={mobileMenuClosedClassNames}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={mobileItemClassNames}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <NavigationItem
            label="HOT"
            to="/"
            isMobile={true}
            isActive={page === "hot"}
          />
          <NavigationItem
            label="TRENDING"
            to="/trending"
            isMobile={true}
            isActive={page === "trending"}
          />
          <NavigationItem
            label="NEW"
            to="/new"
            isMobile={true}
            isActive={page === "new"}
          />
          <NavigationItem
            label="STREAMERS"
            to="/streamers"
            isMobile={true}
            isActive={page === "streamers"}
          />
          <NavigationItem
            label="CATEGORIES"
            to="/categories"
            isMobile={true}
            isActive={page === "categories"}
          />
          <NavigationItem
            label="SEARCH"
            to="/search"
            isMobile={true}
            isActive={page === "search"}
          />
        </div>
      </div>
    </nav>
  );
};
