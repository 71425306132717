import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import useAsyncEffect from "use-async-effect";
import { getCategories } from "../api/category";
import { useTitle } from "../common/useTitle";
import { CategoryFeed } from "../components/CategoryFeed";
import { LoadingIcon } from "../components/LoadingIcon";
import { PageFluid } from "../components/Page";
import { Category } from "../models/Category";

export const CategoriesRoute = (): JSX.Element => {
  useTitle("Categories - LIVESTREAMFAILS");
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [queryAfter, setQueryAfter] = useState<number>();

  useAsyncEffect(
    async () => {
      await loadItems();
    },
    () => {
      setCategories([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
    },
    []
  );

  const loadItems = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const newCategories = await getCategories({
      queryAfter: queryAfter || undefined,
    });
    if (!newCategories.length) {
      setIsFinishedLoading(true);
      return;
    }
    setCategories([...categories, ...newCategories]);
    setQueryAfter(newCategories.slice(-1)[0].id);
    setIsLoading(false);
  };

  return (
    <PageFluid page={"categories"}>
      <InfiniteScroll
        pageStart={0}
        hasMore={!isFinishedLoading}
        loadMore={loadItems}
        loader={<LoadingIcon />}
      >
        <CategoryFeed categories={categories}></CategoryFeed>
      </InfiniteScroll>
    </PageFluid>
  );
};
