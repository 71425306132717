import classNames from "classnames";
import { formatDistanceToNowStrict } from "date-fns";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { MEDIA_IMAGE_URL } from "../common/constants";
import { Clip } from "../models/Clip";
import ReactGA from "react-ga";
import { TrendingUpIcon } from "@heroicons/react/solid";

interface Props {
  clips: Clip[];
  label: string;
}

interface ClipProps {
  clip: Clip | undefined;
  label: string;
}

const HorizontalClip = (props: ClipProps): JSX.Element => {
  const { clip, label } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  if (!clip) {
    return (
      <div>
        <div className="aspect-w-16 aspect-h-9">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="py-3 px-3 sm:px-0">
          <Skeleton count={3} />
        </div>
      </div>
    );
  }

  const imageUrl = MEDIA_IMAGE_URL + "image/" + clip.imageId;
  const imageClassNames = classNames({
    "w-full": true,
    "invisible w-0 h-0": !isLoaded,
  });

  return (
    <Link
      to={"/clip/" + clip.id}
      className="w-full"
      onClick={(e) =>
        ReactGA.event({
          category: "Clip",
          action: "Clicked " + label,
          label: clip?.id.toString() || "",
          value: clip?.redditScore || 0,
        })
      }
    >
      <img
        src={imageUrl}
        alt={clip.label}
        className={imageClassNames}
        onLoad={() => setIsLoaded(true)}
      />
      {!isLoaded && (
        <div className="aspect-w-16 aspect-h-9">
          <Skeleton className="w-full h-full" />
        </div>
      )}
      <div className="py-3 px-3 sm:px-0">
        <div className="text-sm font-bold truncate-2-lines">{clip.label}</div>
        <div className="flex text-gray-600 text-sm">
          <div className="">{clip.streamer?.label}</div>
        </div>
        <div className="flex text-gray-600 text-sm">
          <div className="">{clip?.redditScore + " points"}</div>
          <div className="px-1">·</div>
          <div className="">
            {formatDistanceToNowStrict(new Date(clip?.createdAt), {
              addSuffix: true,
            })}
          </div>
        </div>
      </div>
    </Link>
  );
};

export const HorizontalClips = (props: Props): JSX.Element => {
  const { clips, label } = props;
  const clipsItems = clips.map((clip) => (
    <HorizontalClip key={clip.id} clip={clip} label={label}></HorizontalClip>
  ));
  const skeletonClipsItems = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
  ].map((id) => (
    <HorizontalClip key={id} clip={undefined} label={label}></HorizontalClip>
  ));

  return (
    <>
      <div className="font-bold pb-3 px-2 sm:px-0 flex items-center">
        <TrendingUpIcon className="h-6 w-6 text-red-700 mr-2 md:mt-0.5 md:-mb-0.5" />
        {label}
      </div>
      <div className="grid sm:grid-cols-4 sm:gap-4">
        {clips.length ? clipsItems : skeletonClipsItems}
      </div>
    </>
  );
};
