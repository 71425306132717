import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getClip, getClips } from "../api/clip";
import { Page } from "../components/Page";
import { VerticalClips } from "../components/VerticalClips";
import { VideoPlayer } from "../components/VideoPlayer";
import Skeleton from "react-loading-skeleton";
import { QuerySort } from "../models/QuerySort";
import { HorizontalClips } from "../components/HorizontalClips";
import { OutLink } from "../components/OutLink";
import { Clip } from "../models/Clip";
import useAsyncEffect from "use-async-effect";
import { useTitle } from "../common/useTitle";
import InfiniteScroll from "react-infinite-scroller";
import { LoadingIcon } from "../components/LoadingIcon";
import { sub, formatDistanceToNowStrict } from "date-fns/esm";
import AdSense from "react-adsense";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import { AdVideo } from "../components/AdVideo";
import { isDesktop, isMobile } from "../common/helper";

interface RouteParams {
  id: string;
}
export const ClipRoute = (): JSX.Element => {
  const { id } = useParams<RouteParams>();
  const [clip, setClip] = useState<Clip>();
  useTitle(() => clip?.label || "Loading...", [clip]);
  const [popularClips, setPopularClips] = useState<Clip[]>([]);

  const [todayTopClips, setTodayTopClips] = useState<Clip[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [queryAfter, setQueryAfter] = useState<number>();

  useAsyncEffect(
    async () => {
      try {
        const clip: Clip = await getClip(Number(id));
        setClip(clip);
      } catch (error) {}
      try {
        const clips: Clip[] = await getClips({
          queryTime: sub(new Date(), {
            weeks: 1,
          }),
          querySort: QuerySort.TOP,
        });
        setPopularClips(clips.splice(0, 20));
      } catch (error) {}
      loadItems();
    },
    () => {
      setClip(undefined);
      setPopularClips([]);
      setTodayTopClips([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
    },
    [id]
  );

  const loadItems = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const newClips = await getClips({
      queryTime: sub(new Date(), {
        hours: 23,
      }),
      querySort: QuerySort.TOP,
      queryAfter: queryAfter || undefined,
    });
    if (!newClips.length) {
      setIsFinishedLoading(true);
      return;
    }
    setTodayTopClips([...todayTopClips, ...newClips]);
    setQueryAfter(newClips.slice(-1)[0].id);
    setIsLoading(false);
  };

  return (
    <Page>
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex">
          <div className="flex-1">
            <div className="sm:mb-5">
              {isDesktop() && (
                <div className="hidden sm:block mb-5">
                  <AdVideo />
                </div>
              )}
              {/*isMobile() && clip && !clip.isNSFW && (
                <div className="sm:hidden mb-5 w-full h-10">
                  <AdSense.Google
                    client="ca-pub-2185006140544406"
                    slot="9812114138"
                    style={{ display: "block" }}
                    format="auto"
                    responsive="true"
                  />
                </div>
              )*/}
              <div className="w-full">
                <VideoPlayer clip={clip} />
              </div>
            </div>
            <div className="bg-white p-3 mb-3 sm:p-5 sm:mb-5 sm:rounded">
              {clip && (
                <>
                  <div className="font-bold pb-0.5">{clip?.label}</div>
                  {clip?.streamer?.label && clip?.category?.label && (
                    <div className="flex text-gray-600 pb-0.5 text-sm sm:text-base">
                      <Link
                        to={"/streamer/" + clip?.streamer?.id}
                        className="text-red-700"
                      >
                        {clip?.streamer?.label}
                      </Link>
                      <div className="mx-1">playing</div>
                      <Link
                        to={"/category/" + clip?.category?.id}
                        className="text-red-700"
                      >
                        {clip?.category?.label}
                      </Link>
                    </div>
                  )}
                  <div className="flex text-gray-600 text-sm sm:text-base">
                    <div className="">{clip?.redditScore + " points"}</div>
                    <div className="px-1">·</div>
                    <div className="">
                      {formatDistanceToNowStrict(new Date(clip?.createdAt), {
                        addSuffix: true,
                      })}
                    </div>
                    <div className="px-1">·</div>
                    <OutLink
                      href={
                        "http://www.reddit.com/r/LivestreamFail/comments/" +
                        clip?.redditId
                      }
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <div className="flex items-center">
                        Source
                        <ExternalLinkIcon className="h-4 w-4 text-red-700 ml-1 md:mt-0.5 md:-mb-0.5" />
                      </div>
                    </OutLink>
                  </div>
                </>
              )}
              {!clip && <Skeleton count={3} />}
            </div>
            {clip && !clip.isNSFW && (
              <div className="mb-5">
                <AdSense.Google
                  client="ca-pub-2185006140544406"
                  slot="6343183313"
                  style={{ display: "block" }}
                  format="auto"
                  responsive="true"
                />
              </div>
            )}
            <div className="mb-5">
              <InfiniteScroll
                pageStart={0}
                hasMore={!isFinishedLoading}
                loadMore={loadItems}
                loader={<LoadingIcon />}
              >
                <HorizontalClips
                  clips={todayTopClips}
                  label="Today's Top Clips"
                />
              </InfiniteScroll>
            </div>
            {/*
            <div className="sm:hidden p-3">
              <VerticalClips clips={popularClips} label="Today's Top Clips" />
            </div>
            */}
            {/*
            <div className="mb-5 hidden sm:block">
              <MoreClips clips={moreClips} label="Popular Clips from xQcOW" />
            </div>
            <div className="mb-5 hidden sm:block">
              <MoreClips
                clips={moreClips}
                label="Popular Clips from Grand Theft Auto V"
              />
            </div>
          */}
          </div>
          <div className="hidden lg:block w-4/12 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full">
              <div className="pl-10">
                <VerticalClips clips={popularClips} label="Popular Clips" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
