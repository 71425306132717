import React from "react";
import { ChartBarIcon } from "@heroicons/react/solid";
import { QuerySort } from "../models/QuerySort";

interface Props {
  onChange: Function;
}

export const SortBy = (props: Props): JSX.Element => {
  const stringToQuerySort = (option: string): QuerySort | undefined => {
    if (option === "New") {
      return QuerySort.NEW;
    }
    if (option === "Top") {
      return QuerySort.TOP;
    }
    return undefined;
  };

  return (
    <div className="flex items-center">
      <ChartBarIcon className="h-10 w-10 mr-2 md:mt-0.5 md:-mb-0.5" />
      <div className="w-36">SORT BY</div>
      <select
        className="form-select block w-full rounded-md text-red-700 bg-red-100 border-none"
        onChange={(event) =>
          props.onChange(stringToQuerySort(event.target.value))
        }
      >
        <option>New</option>
        <option>Top</option>
      </select>
    </div>
  );
};
