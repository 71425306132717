import { useEffect } from "react";

/**
 * Update the document title with provided string
 * @param titleOrFn can be a String or a function.
 * @param deps? if provided, the title will be updated when one of these values changes
 */
export function useTitle(titleOrFn: string | Function, ...deps: any) {
  useEffect(() => {
    document.title = typeof titleOrFn === "function" ? titleOrFn() : titleOrFn;
  }, [...deps]);
}
