import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { getStreamer, getStreamerClips } from "../api/streamer";
import { MEDIA_IMAGE_URL } from "../common/constants";
import { useTitle } from "../common/useTitle";
import { ClipFeed } from "../components/ClipFeed";
import { LoadingIcon } from "../components/LoadingIcon";
import { OutLink } from "../components/OutLink";
import { PageFluid } from "../components/Page";
import { Clip } from "../models/Clip";
import { QuerySort } from "../models/QuerySort";
import { Streamer } from "../models/Streamer";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import { SortBy } from "../components/SortBy";
import { getLimitByTimeDate, LimitByTime } from "../components/LimitByTime";
import { QueryTime } from "../models/QueryTime";

interface RouteParams {
  id: string;
}

export const StreamerRoute = (): JSX.Element => {
  const { id } = useParams<RouteParams>();
  const [streamer, setStreamer] = useState<Streamer>();
  useTitle(() => streamer?.label || "Loading...", [streamer]);
  const [clips, setClips] = useState<Clip[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [queryAfter, setQueryAfter] = useState<number>();
  const [sortBy, setSortBy] = useState<QuerySort>(QuerySort.NEW);
  const [limitByTime, setLimitByTime] = useState<QueryTime>(QueryTime.ALL_TIME);
  const [shouldLoad, setShouldLoad] = useState(false);

  useAsyncEffect(
    async () => {
      const streamer: Streamer = await getStreamer(Number(id));
      setStreamer(streamer);
      setShouldLoad(true);
    },
    () => {
      setClips([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
      setShouldLoad(false);
    },
    []
  );

  const loadItems = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const newClips = await getStreamerClips(Number(id), {
      querySort: sortBy,
      queryAfter: queryAfter || undefined,
      queryTime: limitByTime ? getLimitByTimeDate(limitByTime) : undefined,
    });
    if (!newClips.length) {
      setIsFinishedLoading(true);
    } else {
      setClips([...clips, ...newClips]);
      setQueryAfter(newClips.slice(-1)[0].id);
    }
    setIsLoading(false);
    setShouldLoad(false);
  };

  useAsyncEffect(async () => {
    if (shouldLoad) {
      // Refresh items
      await loadItems();
    }
  }, [shouldLoad]);

  useAsyncEffect(
    () => {},
    () => {
      setClips([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
      setShouldLoad(true);
    },
    [sortBy, limitByTime]
  );

  const sortByOnChange = async (value: QuerySort) => {
    setSortBy(value);
  };

  const limitByTimeOnChange = async (value: QueryTime) => {
    setLimitByTime(value);
  };

  const imageUrl = MEDIA_IMAGE_URL + "image/" + streamer?.imageId;

  return (
    <PageFluid page={"streamer"}>
      <div className="bg-white p-3 mb-3 sm:p-5 sm:mb-5 sm:rounded max-w-4xl mx-auto">
        {streamer && (
          <>
            <div className="flex justify-start">
              <div>
                <img
                  className="h-28 w-28 sm:rounded"
                  src={imageUrl}
                  alt="Streamer"
                />
              </div>
              <div className="ml-5">
                <div className="font-bold pb-0.5">{streamer.label}</div>
                <OutLink
                  href={streamer?.sourceLink}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <div className="flex items-center">
                    Twitch Channel
                    <ExternalLinkIcon className="h-4 w-4 text-red-700 ml-1 md:mt-0.5 md:-mb-0.5" />
                  </div>
                </OutLink>
              </div>
            </div>
          </>
        )}
        {!streamer && <Skeleton count={3} />}
      </div>

      <div className="flex justify-end w-full px-5 py-5 md:p-0 md:pb-5">
        <SortBy onChange={sortByOnChange} />
      </div>

      <div className="flex justify-end w-full px-5 py-5 md:p-0 md:pb-5">
        <LimitByTime onChange={limitByTimeOnChange} />
      </div>

      <InfiniteScroll
        pageStart={0}
        hasMore={!isFinishedLoading}
        loadMore={() => setShouldLoad(true)}
        loader={<LoadingIcon />}
      >
        <ClipFeed clips={clips}></ClipFeed>
      </InfiniteScroll>
    </PageFluid>
  );
};
