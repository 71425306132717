import React from "react";

interface Props {
  href: string;
  children: JSX.Element[] | JSX.Element | string;
  [x: string]: any;
}

export const OutLink = (props: Props): JSX.Element => {
  const { href, target, children, ...otherProps } = props;
  return (
    <a href={href} target={target} {...otherProps} className="text-red-700">
      {children}
    </a>
  );
};
