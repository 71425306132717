/**
 * Returns a boolean that is true if the user is on a mobile device based on the
 * users user-agent.
 */
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function isDesktop() {
  return !isMobile();
}
