import { useScript } from "../hooks/useScript";

export const AdVideo = (): JSX.Element => {
  const scriptSrc =
    "//pt.potwmora.com/embed/livefeed/346?site=oranum&psid=fikfap&pstool=600_346&psprogram=revs&campaign_id=121154&subaffid={SUBAFFID}&ms_notrack=1";
  const scriptId = "AdVideoScript";

  const scriptCallbackFunction = () => {
    // Function that gets triggered by the useScript hook, we can use this to display a loading icon if needed.
  };
  const adScript = useScript(scriptSrc, scriptId, scriptCallbackFunction);
  return (
    <div className="flex justify-center">
      <div id="oranum_livefeed_container"></div>
    </div>
  );
};
