import React from "react";
import { Streamer } from "../models/Streamer";
import { StreamerFeedItem } from "./StreamerFeedItem";

interface Props {
  streamers: Streamer[];
}

export const StreamerFeed = (props: Props): JSX.Element => {
  const { streamers } = props;
  const feedItems = streamers.map((streamer) => (
    <StreamerFeedItem streamer={streamer} key={streamer.id} />
  ));
  const skeletonFeedItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => (
    <StreamerFeedItem streamer={undefined} key={id} />
  ));

  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-2 p-4 sm:p-0 sm:grid-cols-feedSmall sm:gap-x-4 sm:gap-y-2">
      {streamers.length ? feedItems : skeletonFeedItems}
    </div>
  );
};
