import { roundToNearestMinutes } from "date-fns";
import { API_URL } from "../common/constants";
import { Clip } from "../models/Clip";
import { GetClipsQuery } from "../models/GetClipsQuery";
import { Category } from "../models/Category";
import { GetCategoriesQuery } from "../models/GetCategoriesQuery";

export async function getCategory(id: number): Promise<Category> {
  const response = await fetch(API_URL + "category/" + id);
  const category: Category = await response.json();
  return category;
}

export async function getCategoryClips(
  categoryId: number,
  getClipsQuery?: GetClipsQuery
): Promise<Clip[]> {
  const url = new URL(API_URL + "category/" + categoryId + "/clips");

  if (getClipsQuery?.querySort) {
    url.searchParams.set("querySort", getClipsQuery.querySort);
  }
  if (getClipsQuery?.queryAfter) {
    url.searchParams.set("queryAfter", getClipsQuery.queryAfter.toString());
  }
  if (getClipsQuery?.queryTime) {
    // Round in order to enable caching
    const queryTimeRounded = roundToNearestMinutes(getClipsQuery.queryTime, {
      nearestTo: 5,
    });
    url.searchParams.set("queryTime", queryTimeRounded.toISOString());
  }
  if (getClipsQuery?.queryMinScore) {
    url.searchParams.set(
      "queryMinScore",
      getClipsQuery.queryMinScore.toString()
    );
  }
  if (getClipsQuery?.queryMaxScore) {
    url.searchParams.set(
      "queryMaxScore",
      getClipsQuery.queryMaxScore.toString()
    );
  }

  const response = await fetch(url.toString());
  const clips: Clip[] = await response.json();
  return clips;
}

export async function getCategories(
  getCategoriesQuery?: GetCategoriesQuery
): Promise<Category[]> {
  const url = new URL(API_URL + "categories");

  if (getCategoriesQuery?.queryAfter) {
    url.searchParams.set(
      "queryAfter",
      getCategoriesQuery.queryAfter.toString()
    );
  }
  const response = await fetch(url.toString());
  const categories: Category[] = await response.json();
  return categories;
}
