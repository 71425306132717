import { roundToNearestMinutes } from "date-fns/esm";
import { API_URL } from "../common/constants";
import { Clip } from "../models/Clip";
import { GetClipsQuery } from "../models/GetClipsQuery";

export async function getClips(getClipsQuery?: GetClipsQuery): Promise<Clip[]> {
  const url = new URL(API_URL + "clips");

  if (getClipsQuery?.querySort) {
    url.searchParams.set("querySort", getClipsQuery.querySort);
  }
  if (getClipsQuery?.queryAfter) {
    url.searchParams.set("queryAfter", getClipsQuery.queryAfter.toString());
  }
  if (getClipsQuery?.queryTime) {
    // Round in order to enable caching
    const queryTimeRounded = roundToNearestMinutes(getClipsQuery.queryTime, {
      nearestTo: 5,
    });
    url.searchParams.set("queryTime", queryTimeRounded.toISOString());
  }
  if (getClipsQuery?.queryMinScore) {
    url.searchParams.set(
      "queryMinScore",
      getClipsQuery.queryMinScore.toString()
    );
  }
  if (getClipsQuery?.queryMaxScore) {
    url.searchParams.set(
      "queryMaxScore",
      getClipsQuery.queryMaxScore.toString()
    );
  }

  const response = await fetch(url.toString());
  const clips: Clip[] = await response.json();
  return clips;
}

export async function getClip(id: number): Promise<Clip> {
  const response = await fetch(API_URL + "clip/" + id);
  const clip: Clip = await response.json();
  return clip;
}
