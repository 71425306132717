import { roundToNearestMinutes } from "date-fns";
import { API_URL } from "../common/constants";
import { Clip } from "../models/Clip";
import { GetClipsQuery } from "../models/GetClipsQuery";
import { GetStreamersQuery } from "../models/GetStreamersQuery";
import { Streamer } from "../models/Streamer";

export async function getStreamer(id: number): Promise<Streamer> {
  const response = await fetch(API_URL + "streamer/" + id);
  const streamer: Streamer = await response.json();
  return streamer;
}

export async function getStreamerClips(
  streamerId: number,
  getClipsQuery?: GetClipsQuery
): Promise<Clip[]> {
  const url = new URL(API_URL + "streamer/" + streamerId + "/clips");

  if (getClipsQuery?.querySort) {
    url.searchParams.set("querySort", getClipsQuery.querySort);
  }
  if (getClipsQuery?.queryAfter) {
    url.searchParams.set("queryAfter", getClipsQuery.queryAfter.toString());
  }
  if (getClipsQuery?.queryTime) {
    // Round in order to enable caching
    const queryTimeRounded = roundToNearestMinutes(getClipsQuery.queryTime, {
      nearestTo: 5,
    });
    url.searchParams.set("queryTime", queryTimeRounded.toISOString());
  }
  if (getClipsQuery?.queryMinScore) {
    url.searchParams.set(
      "queryMinScore",
      getClipsQuery.queryMinScore.toString()
    );
  }
  if (getClipsQuery?.queryMaxScore) {
    url.searchParams.set(
      "queryMaxScore",
      getClipsQuery.queryMaxScore.toString()
    );
  }

  const response = await fetch(url.toString());
  const clips: Clip[] = await response.json();
  return clips;
}

export async function getStreamers(
  getStreamersQuery?: GetStreamersQuery
): Promise<Streamer[]> {
  const url = new URL(API_URL + "streamers");

  if (getStreamersQuery?.queryAfter) {
    url.searchParams.set("queryAfter", getStreamersQuery.queryAfter.toString());
  }
  const response = await fetch(url.toString());
  const streamers: Streamer[] = await response.json();
  return streamers;
}
