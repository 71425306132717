import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import useAsyncEffect from "use-async-effect";
import { getClips } from "../api/clip";
import { useTitle } from "../common/useTitle";
import { ClipFeed } from "../components/ClipFeed";
import { getLimitByTimeDate, LimitByTime } from "../components/LimitByTime";
import { LoadingIcon } from "../components/LoadingIcon";
import { PageFluid } from "../components/Page";
import { SortBy } from "../components/SortBy";
import { Clip } from "../models/Clip";
import { QuerySort } from "../models/QuerySort";
import { QueryTime } from "../models/QueryTime";

export const HotRoute = (): JSX.Element => {
  useTitle("Hot Clips - LIVESTREAMFAILS");
  const [clips, setClips] = useState<Clip[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [queryAfter, setQueryAfter] = useState<number>();
  const [sortBy, setSortBy] = useState<QuerySort>(QuerySort.NEW);
  const [limitByTime, setLimitByTime] = useState<QueryTime>(QueryTime.ALL_TIME);
  const [shouldLoad, setShouldLoad] = useState(false);

  useAsyncEffect(
    async () => {
      setShouldLoad(true);
    },
    () => {
      setClips([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
      setShouldLoad(false);
    },
    []
  );

  const loadItems = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const newClips = await getClips({
      queryMinScore: 500,
      querySort: sortBy,
      queryAfter: queryAfter || undefined,
      queryTime: limitByTime ? getLimitByTimeDate(limitByTime) : undefined,
    });
    if (!newClips.length) {
      setIsFinishedLoading(true);
    } else {
      setClips([...clips, ...newClips]);
      setQueryAfter(newClips.slice(-1)[0].id);
    }
    setIsLoading(false);
    setShouldLoad(false);
  };

  useAsyncEffect(async () => {
    if (shouldLoad) {
      // Refresh items
      await loadItems();
    }
  }, [shouldLoad]);

  useAsyncEffect(
    () => {},
    () => {
      setClips([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
      setShouldLoad(true);
    },
    [sortBy, limitByTime]
  );

  const sortByOnChange = async (value: QuerySort) => {
    setSortBy(value);
  };

  const limitByTimeOnChange = async (value: QueryTime) => {
    setLimitByTime(value);
  };

  return (
    <PageFluid page={"hot"}>
      <div className="flex justify-end w-full px-5 py-5 md:p-0 md:pb-5">
        <SortBy onChange={sortByOnChange} />
      </div>
      <div className="flex justify-end w-full px-5 py-5 md:p-0 md:pb-5">
        <LimitByTime onChange={limitByTimeOnChange} />
      </div>
      <InfiniteScroll
        pageStart={0}
        hasMore={!isFinishedLoading}
        loadMore={() => setShouldLoad(true)}
        loader={<LoadingIcon />}
      >
        <ClipFeed clips={clips}></ClipFeed>
      </InfiniteScroll>
    </PageFluid>
  );
};
