import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import useAsyncEffect from "use-async-effect";
import { getStreamers } from "../api/streamer";
import { useTitle } from "../common/useTitle";
import { StreamerFeed } from "../components/StreamerFeed";
import { LoadingIcon } from "../components/LoadingIcon";
import { PageFluid } from "../components/Page";
import { Streamer } from "../models/Streamer";

export const StreamersRoute = (): JSX.Element => {
  useTitle("Streamers - LIVESTREAMFAILS");
  const [streamers, setStreamers] = useState<Streamer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [queryAfter, setQueryAfter] = useState<number>();

  useAsyncEffect(
    async () => {
      await loadItems();
    },
    () => {
      setStreamers([]);
      setIsLoading(false);
      setIsFinishedLoading(false);
      setQueryAfter(undefined);
    },
    []
  );

  const loadItems = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const newStreamers = await getStreamers({
      queryAfter: queryAfter || undefined,
    });
    if (!newStreamers.length) {
      setIsFinishedLoading(true);
      return;
    }
    setStreamers([...streamers, ...newStreamers]);
    setQueryAfter(newStreamers.slice(-1)[0].id);
    setIsLoading(false);
  };

  return (
    <PageFluid page={"streamers"}>
      <InfiniteScroll
        pageStart={0}
        hasMore={!isFinishedLoading}
        loadMore={loadItems}
        loader={<LoadingIcon />}
      >
        <StreamerFeed streamers={streamers}></StreamerFeed>
      </InfiniteScroll>
    </PageFluid>
  );
};
