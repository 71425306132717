import classNames from "classnames";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { MEDIA_IMAGE_URL } from "../common/constants";
import { Clip } from "../models/Clip";
import { formatDistanceToNowStrict } from "date-fns";

interface Props {
  clip: Clip | undefined;
}

export const ClipFeedItem = (props: Props): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { clip } = props;

  if (!clip) {
    return (
      <div>
        <div className="aspect-w-16 aspect-h-9">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="py-3 px-3 sm:px-0 text-sm sm:text-base">
          <Skeleton count={3} />
        </div>
      </div>
    );
  }

  const imageUrl = MEDIA_IMAGE_URL + "image/" + clip.imageId;
  const imageClassNames = classNames({
    "w-full": true,
    "invisible w-0 h-0": !isLoaded,
  });

  return (
    <Link to={"/clip/" + clip.id} className="w-full">
      <div>
        <img
          src={imageUrl}
          alt={clip.label}
          className={imageClassNames}
          onLoad={() => setIsLoaded(true)}
        />
        {!isLoaded && (
          <div className="aspect-w-16 aspect-h-9">
            <Skeleton className="w-full h-full" />
          </div>
        )}
        <div className="py-3 px-3 sm:px-0 text-sm sm:text-base">
          <div className="font-bold truncate-2-lines">{clip?.label}</div>
          {clip?.streamer && (
            <div className="flex text-gray-600 text-sm">
              <div className="">{clip?.streamer?.label}</div>
            </div>
          )}
          <div className="flex text-gray-600 text-sm">
            <div className="">{clip?.redditScore + " points"}</div>
            <div className="px-1">·</div>
            <div className="">
              {formatDistanceToNowStrict(new Date(clip?.createdAt), {
                addSuffix: true,
              })}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
