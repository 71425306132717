import React from "react";
import { Clip } from "../models/Clip";
import { ClipFeedItem } from "./ClipFeedItem";

interface Props {
  clips: Clip[];
}

export const ClipFeed = (props: Props): JSX.Element => {
  const { clips } = props;
  const feedItems = clips.map((clip) => (
    <ClipFeedItem clip={clip} key={clip.id} />
  ));
  const skeletonFeedItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => (
    <ClipFeedItem clip={undefined} key={id} />
  ));

  return (
    <div className="grid grid-cols-1 sm:grid-cols-feed sm:gap-x-4 sm:gap-y-2">
      {clips.length ? feedItems : skeletonFeedItems}
    </div>
  );
};
