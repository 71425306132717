import React from "react";
import ImgLogo from "../assets/images/logo-white.png";

export const Logo = (): JSX.Element => {
  return (
    <div className="flex items-center justify-center p-2 bg-red-700 rounded-md">
      <img className="h-5 w-5" src={ImgLogo} alt="Logo" />
    </div>
  );
};

export const LogoWithText = (): JSX.Element => {
  return (
    <div className="flex items-center">
      <Logo />
      <div
        className="text-3xl pl-4 font-bebas text-red-700"
        style={LogoWithTextStyle}
      >
        LIVESTREAMFAILS
      </div>
    </div>
  );
};

const LogoWithTextStyle = {
  marginBottom: "-3px",
};
