import React from "react";
import { Body, BodyFluid } from "./Body";
import { Navigation } from "./Navigation";

interface Props {
  children: JSX.Element[] | JSX.Element | string;
  page?: string;
}

export const Page = (props: Props): JSX.Element => {
  const { children, page } = props;

  return (
    <>
      <Navigation page={page} />
      <Body>{children}</Body>
    </>
  );
};

export const PageFluid = (props: Props): JSX.Element => {
  const { children, page } = props;

  return (
    <>
      <Navigation page={page} />
      <BodyFluid>{children}</BodyFluid>
    </>
  );
};
