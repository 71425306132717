import React, { useEffect } from "react";
import { Page } from "../components/Page";
import { useTitle } from "../common/useTitle";

export const SearchRoute = (): JSX.Element => {
  useTitle("Search - LIVESTREAMFAILS");

  useEffect(() => {
    (function () {
      var cx = "35352b4a541f8742f";
      var gcse = document.createElement("script");
      gcse.type = "text/javascript";
      gcse.async = true;
      gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
      var s = document.getElementsByTagName("script")[0];
      // @ts-ignore
      s.parentNode.insertBefore(gcse, s);
    })();
  });
  return (
    <Page page={"search"}>
      <div className="gcse-search"></div>
    </Page>
  );
};
