import Loader from "react-loader-spinner";

export const LoadingIcon = (): JSX.Element => {
  return (
    <div className="flex justify-center my-10">
      <Loader
        type="TailSpin"
        color="rgba(185, 28, 28, var(--tw-bg-opacity))"
        height={30}
        width={30}
      />
    </div>
  );
};
