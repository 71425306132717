import * as env from "env-var";
import { Stage } from "../models/Stage";

export const STAGE = env.get("REACT_APP_STAGE").required().asString();

export const IS_LOCAL_STAGE = STAGE === Stage.LOCAL;
export const IS_DEV_STAGE = STAGE === Stage.DEV;
export const IS_PROD_STAGE = STAGE === Stage.PROD;
export const IS_LOCAL_OR_DEV_STAGE = IS_LOCAL_STAGE || IS_DEV_STAGE;

export const MEDIA_IMAGE_URL = env
  .get("REACT_APP_MEDIA_IMAGE_URL")
  .required()
  .asString();

export const MEDIA_VIDEO_URL = env
  .get("REACT_APP_MEDIA_VIDEO_URL")
  .required()
  .asString();

export const API_URL = env.get("REACT_APP_API_URL").required().asString();
